import React, {FC} from 'react'
import {InputType, GeneralInputType, PropiedadParametrica, FormatAPI} from './Interfaces'
import InputDatePicker from './InputDatePicker'
import InputComplete from './InputComplete'
import CheckBoxComplete from './CheckBoxComplete'
import * as Yup from 'yup'
import Select2Complete from './Select2Complete'
import {OptionsType} from './Interfaces'
import FileInput from './FileInput'

const MakeSchema = (obj: GeneralInputType[]) => {
  let currentSchemaObj: PropiedadParametrica = {}
  for (var i = 0; i <= obj.length; i++) {
    const obj_widget = obj[i]?.titulo
    if (obj_widget !== undefined) {
      let objYup: any = Yup
      if (obj[i].campo_usuario.tipo === 'fecha') {
        objYup = objYup.date()
      } else if (obj[i].campo_usuario.tipo === 'check') {
        objYup = objYup.boolean()
      } else if (obj[i].campo_usuario.tipo === 'texto_largo') {
        objYup = objYup.mixed()
      } else if (obj[i].campo_usuario.tipo === 'correo') {
        objYup = objYup.string().email('Ingrese un correo valido')
      } else if (obj[i].campo_usuario.tipo === 'entero') {
        objYup = objYup.number('Ingrese un numero')
      } else if (obj[i].campo_usuario.tipo === 'decimal') {
        objYup = objYup.number('Ingrese un numero')
      } else {
        objYup = objYup.string()
      }
      if (obj[i].requerido) {
        objYup = objYup.required(`${obj_widget} es un campo requerido.`)
      }
      currentSchemaObj[obj_widget] = objYup
    }
  }
  return Yup.object(currentSchemaObj)
}

const GeneralInput: FC<InputType> = ({obj, errors, touched, values}) => {
  const tamanio =
    obj.campo_usuario.tipo === 'texto_largo' ? 'col-lg-12 col-md-12' : 'col-lg-6 col-md-6'
  let listado: OptionsType[] = []
  const lista_temporal =
    obj.campo_usuario.lista_seleccion !== null ? obj.campo_usuario.lista_seleccion : null
  if (lista_temporal !== null && lista_temporal !== undefined) {
    for (var i = 0; i < lista_temporal.length; i++) {
      const new_obj: OptionsType = {value: lista_temporal[i], label: lista_temporal[i]}
      listado.push(new_obj)
    }
  }
  return (
    <div className={tamanio}>
      {obj.campo_usuario.tipo === 'fecha' && (
        <InputDatePicker
          errors={errors}
          touched={touched}
          label={obj.titulo}
          name={obj.titulo}
          placeholder={obj.titulo}
        />
      )}
      {obj.campo_usuario.tipo === 'archivo' && (
        <FileInput label={obj.titulo} name={obj.titulo} required={obj.requerido} />
      )}
      {obj.campo_usuario.tipo === 'texto_largo' && (
        <InputComplete
          errors={errors}
          touched={touched}
          as={'textarea'}
          label={obj.titulo}
          name={obj.titulo}
          placeholder={obj.titulo}
          values={values}
          required={obj.requerido}
        />
      )}
      {obj.campo_usuario.tipo === 'check' && (
        <CheckBoxComplete
          errors={errors}
          type='checkbox'
          touched={touched}
          label={obj.titulo}
          name={obj.titulo}
          required={false}
        />
      )}
      {(obj.campo_usuario.tipo === 'decimal' ||
        obj.campo_usuario.tipo === 'entero' ||
        obj.campo_usuario.tipo === 'texto' ||
        obj.campo_usuario.tipo === 'correo') && (
        <InputComplete
          errors={errors}
          touched={touched}
          label={obj.titulo}
          name={obj.titulo}
          placeholder={obj.titulo}
          values={values}
          readonly={
            obj.campo_usuario.ruta_campo !== '' && obj.campo_usuario.ruta_campo !== null
              ? true
              : false
          }
        />
      )}
      {obj.campo_usuario.tipo === 'lista' && (
        <Select2Complete
          options={listado}
          errors={errors}
          touched={touched}
          label={obj.titulo}
          name={obj.titulo}
          placeholder={obj.titulo}
        />
      )}
    </div>
  )
}
const ConstructForm: Function = (
  response: any,
  StopLoading: Function,
  setForm: Function,
  setCurrentSchema: Function,
  setInitialValues: Function,
  setValuesAPI: Function,
  currentUser: any
) => {
  StopLoading()
  if (response.valido === true || response.valido === 1) {
    setForm(response.data)
    var obj: GeneralInputType[] = []
    var obj_init: PropiedadParametrica = {}
    var valuesAPITemp: FormatAPI[] = []
    for (var i = 0; i < response.data.length; i++) {
      const obj_resp = response.data[i]
      let obj_temp: GeneralInputType = {
        id: obj_resp?.id,
        titulo: obj_resp?.titulo,
        requerido: obj_resp?.requerido,
        orden: obj_resp?.orden,
        campo_usuario: {
          id: obj_resp?.campo_usuario.id,
          tipo: obj_resp?.campo_usuario.tipo,
          ruta_campo: obj_resp?.campo_usuario.ruta_campo,
        },
      }
      if (obj_resp?.titulo !== undefined) {
        obj_init[obj_resp?.titulo] = ''
        if (
          obj_temp.campo_usuario.ruta_campo !== '' &&
          obj_temp.campo_usuario.ruta_campo !== undefined &&
          obj_temp.campo_usuario.ruta_campo !== null &&
          obj_temp.campo_usuario.ruta_campo in currentUser?.data
        ) {
          obj_init[obj_resp?.titulo] = currentUser?.data[obj_temp.campo_usuario.ruta_campo]
        }
      }
      obj.push(obj_temp)
      const new_objAPI: FormatAPI = {
        campo_usuario: obj_resp?.id,
        nombre_campo: obj_resp?.titulo,
        valor_campo: '',
      }

      valuesAPITemp.push(new_objAPI)
    }
    obj.sort((a, b) => {
      if (a.orden > b.orden) {
        return 1
      } else if (a.orden < b.orden) {
        return -1
      } else {
        return 0
      }
    })
    setCurrentSchema(MakeSchema(obj))
    setInitialValues(obj_init)
    setValuesAPI(valuesAPITemp)
  }
}
function readFile(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (res) => {
      resolve(res.target?.result)
    }
    reader.onerror = (err) => reject(err)

    reader.readAsDataURL(file)
  })
}
const ConstructFormOnSubmit: Function = async (
  valuesAPI: FormatAPI[],
  values: PropiedadParametrica,
  setValuesAPI: Function
) => {
  for (var i = 0; i < valuesAPI.length; i++) {
    const currentObjValuesAPI = valuesAPI[i]
    if (currentObjValuesAPI.nombre_campo in values) {
      if (values[currentObjValuesAPI.nombre_campo] instanceof File) {
        const file = values[currentObjValuesAPI.nombre_campo]
        let base = await readFile(file)
        if (typeof base === 'string') {
          const base64String = base.toString().replace('data:', '').replace(/^.+,/, '')
          valuesAPI[i].valor_campo = base64String
        }
      } else {
        valuesAPI[i].valor_campo = values[currentObjValuesAPI.nombre_campo]
      }
    }
  }
  setValuesAPI(valuesAPI)
}
const ConstructFormOnSubmitNotState: Function = (
  valuesAPI: GeneralInputType[],
  values: PropiedadParametrica
) => {
  var valuesAPITmp: FormatAPI[] = []
  for (var i = 0; i < valuesAPI.length; i++) {
    const obj_resp = valuesAPI[i]
    const new_objAPI: FormatAPI = {
      campo_usuario: obj_resp?.id,
      nombre_campo: obj_resp?.titulo,
      valor_campo: '',
    }
    if (
      obj_resp !== undefined &&
      obj_resp.campo_usuario !== undefined &&
      'ruta_campo' in obj_resp.campo_usuario &&
      obj_resp.campo_usuario.ruta_campo !== undefined &&
      obj_resp.campo_usuario.ruta_campo in values
    ) {
      new_objAPI.valor_campo = values[obj_resp.campo_usuario.ruta_campo]
    }
    valuesAPITmp.push(new_objAPI)
  }
  return valuesAPITmp
}
export {
  GeneralInput,
  MakeSchema,
  ConstructForm,
  ConstructFormOnSubmit,
  ConstructFormOnSubmitNotState,
}
