/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {Dispatch, FC, SetStateAction, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth, UserModel} from '../modules/auth'
import {App} from '../App'
import {NuevoEmpleoPage} from '../modules/nuevo_empleo/NuevoEmpleoPage'
import {useIsAuthenticated, useMsal} from '@azure/msal-react'
import {callMsGraph, loginRequest} from '../modules/auth/components/AuthConfigMS'
import {getDataRequest} from '../components/support/TableRequests'
import {autenticacionMs} from '../modules/auth/core/_requests'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
export const actualiza_usuario = async (
  mail: string,
  codigo_empleado: string,
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
) => {
  const url = process.env.REACT_APP_URL_API_APOYO?.toString() ?? ''
  if (url !== '') {
    await fetch(`${url}/rh/data`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        planillaid: '',
        fecha_inicio: '',
        fecha_fin: '',
        valor_texto: 'correo',
        valor_numero: '',
        valor_texto2: codigo_empleado,
        valor_numero2: '',
        proyecto_id: '',
        token_app: process.env.REACT_APP_TOKEN_APOYO?.toString() ?? '123',
        query: 'api.con_ficha_empleado',
      }),
      headers: {
        'Content-type': 'text/plain',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const registro = data.DATA?.datos?.registro
        if (registro !== undefined) {
          setCurrentUser((currentUser: UserModel | undefined) => {
            if (currentUser !== undefined) {
              return {...currentUser, id_api: registro.id, data: registro, email: registro.email}
            }
          })
          getDataRequest(registro.email, setCurrentUser)
        }
      })
      .catch((e) => {
        alert(e.message)
      })
  }
}
const AppRoutes: FC = () => {
  const {instance, accounts} = useMsal()
  const {currentUser, setCurrentUser, saveAuth, logout} = useAuth()
  const isAuthenticated = useIsAuthenticated()
  const BlankPage = () => {
    return <></>
  }
  useEffect(() => {
    //Usuario autenticado por MS
    if (currentUser === undefined && isAuthenticated) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((responseT: any) => {
          callMsGraph(responseT.accessToken).then(async (response: any) => {
            setCurrentUser({
              id: response.id ?? '',
              username: response.displayName ?? '',
              password: undefined,
              email: response.mail ?? '',
              first_name: response.givenName ?? '',
              last_name: response.givenName ?? '',
              instance: instance,
            })
            //Busca en api
            autenticacionMs(response.mail, setCurrentUser)
            saveAuth({api_token: responseT.accessToken})
          })
        })
        .catch((e) => {
          logout()
          setCurrentUser(undefined)
          saveAuth(undefined)
          instance?.logoutRedirect({
            postLogoutRedirectUri: '/',
          })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='blank_page' element={<BlankPage />} />
          <Route path='error/*' element={<ErrorsPage />} />
          {currentUser || isAuthenticated ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
              <Route path='logout' element={<Logout />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='nuevo_empleo/*' element={<NuevoEmpleoPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
