import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import {PublicClientApplication} from '@azure/msal-browser'
import {MsalProvider} from '@azure/msal-react'
import {msalConfig} from './app/modules/auth/components/AuthConfigMS'
import {FlashMessageContext} from './app/components/support/Alerts'
setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
const msalInstance = new PublicClientApplication(msalConfig)
msalInstance.initialize().then(function () {
  msalInstance.handleRedirectPromise().then(function () {
    if (container) {
      createRoot(container).render(
        <MsalProvider instance={msalInstance}>
          <QueryClientProvider client={queryClient}>
            <MetronicI18nProvider>
              <AuthProvider>
                <FlashMessageContext>
                  <AppRoutes />
                </FlashMessageContext>
              </AuthProvider>
            </MetronicI18nProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </MsalProvider>
      )
    }
  })
})
// This will no longer throw this error since initialize completed before this was invoked
