import {FC, useState} from 'react'
import {UserModel} from '../../modules/auth'
import TableRequestModal from './TableRequestModal'
import {KTIcon} from '../../../_metronic/helpers'
interface TableRequestType {
  currentUser: any
  type: string
}
export const getDataRequest: Function = (email: string, setCurrentUser: Function) => {
  const url = process.env.REACT_APP_URL_API_FORMULARIO?.toString() ?? ''
  if (url !== '') {
    if (email !== '') {
      const key = process.env.REACT_APP_TOKEN_FORMULARIO?.toString() ?? '123'
      fetch(`${url}/ticket/listaSolicitud?correo=${email}&key=${key}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          'Cache-Control': 'no-cache',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.valido === 1) {
            setCurrentUser((currentUser: UserModel | undefined) => {
              if (currentUser !== undefined) {
                return {...currentUser, dataRequest: response.data.CODIGO}
              }
            })
          }
        })
        .catch((e) => {})
    }
  }
}
const TableRequest: FC<TableRequestType> = ({currentUser, type}) => {
  const openModal = (detail: any) => {
    setObj(detail)
  }
  const [obj, setObj] = useState({})
  return (
    <>
      <div className='table-responsive'>
        <table className='table table-striped table-bordered'>
          <thead>
            <tr>
              <th>Código</th>
              <th>Fecha de Solicitud</th>
              <th>Estatus</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            {currentUser !== undefined &&
              typeof currentUser.dataRequest !== 'undefined' &&
              currentUser.dataRequest !== null && (
                <>
                  {currentUser.dataRequest.map(function (detail: any, index: number) {
                    if (detail.proceso.codigo === type) {
                      return (
                        <tr key={`table_body_${index}`}>
                          <td>{detail.codigo}</td>
                          <td align='center'>{detail.fecha}</td>
                          <td>{detail.estatus}</td>
                          <td align='center'>
                            <button
                              className='btn btn-success btn-sm'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_table_detail'
                              onClick={() => {
                                openModal(detail)
                              }}
                            >
                              <span className='indicator-label'>
                                <KTIcon iconName='book' />
                                &nbsp;
                              </span>
                              Detalle
                            </button>
                          </td>
                        </tr>
                      )
                    } else {
                      return null
                    }
                  })}
                </>
              )}
          </tbody>
        </table>
      </div>
      <TableRequestModal obj={obj} />
    </>
  )
}

export default TableRequest
